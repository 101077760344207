<template>
  <div></div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup (props) {
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
    })
    const onRedirect = () => {
      const redirect = Cookies.get('redirect')
      Cookies.remove('redirect')
      useRouter.push(redirect)
    }
    const init = () => {
      console.log(encodeURIComponent(window.location.protocol + '//' + window.location.host + location.pathname))
      if (typeof useRoute.query.openid === 'undefined') {
        Cookies.set('redirect', useRoute.query.redirect, { expires: 1 })
        location.href = 'https://open.feddon.com/api/prod/weixin.weixinOauth' + '?targetUrl=' + encodeURIComponent(window.location.protocol + '//' + window.location.host + location.pathname)
      } else {
        if (typeof Cookies.get('redirect') === 'undefined') {
          useRouter.push({ path: '/' })
        } else {
          Cookies.set('wechatUnionid', useRoute.query.unionid, { expires: 1 })
          Cookies.set('wechatOpenid', useRoute.query.openid, { expires: 1 })
          Cookies.set('wechatNickname', useRoute.query.nickname, { expires: 1 })
          Cookies.set('wechatHeadimgurl', useRoute.query.headimgurl, { expires: 1 })
          onRedirect()
        }
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
